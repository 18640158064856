.cfra-ag-grid {
    span[ref='eSortOrder'] {
        display: none !important;
    }

    .ag-grid-selection-column {
        --ag-cell-horizontal-padding: 10px;
    }

    .ag-theme-alpine {
        --ag-header-background-color: #fff;
        --ag-header-foreground-color: #57626a;
        --ag-cell-horizontal-padding: 29px;
        --ag-font-size: 14px;
        --ag-borders: solid 0px;
    }

    .ag-grid-cfra-watchlist-icons {
        --ag-icon-font-code-checkbox-checked: url('../../assets/images/screener-checked.svg');
        --ag-icon-font-code-checkbox-indeterminate: url('../../assets/images/screener-unchecked.svg');
        --ag-icon-font-code-checkbox-unchecked: url(../../assets/images/screener-unchecked.svg);
        --ag-cell-horizontal-padding: 10px;

        .ag-checkbox-input-wrapper {
            width: 0;
        }
    }

    .ag-header-row {
        font-family: GraphikMedium;
        font-weight: 500;
    }

    .ag-header {
        border-top: 1px solid #74828d;
        border-bottom: 1px solid #74828d;
    }

    .ag-row {
        .company-details-url {
            color: #007bb8;
            text-decoration: none;
        }

        font-family: GraphikRegular;
        color: #002b5a;
        font-size: 14px;
    }

    .ag-header-cell-text {
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        white-space: normal;
        word-wrap: break-word;
    }

    .ag-right-aligned-header .ag-header-cell-text {
        text-align: right;
    }

    .ag-root ::-webkit-scrollbar {
        width: 15px
    }

    .ag-root ::-webkit-scrollbar-button {
        height: 0px
    }

    .ag-root ::-webkit-scrollbar-thumb {
        min-height: 51%;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        background-color: #BDBDBD;
        border-radius: 25px;
    }
}